import { atom, DefaultValue, selector, selectorFamily } from 'recoil';
import { syncEffect } from 'recoil-sync';
import { string } from '@recoiljs/refine';
import { RequestsBase } from '../api/request';
import { ApiResponse } from '../api/api-response.model';
import { config } from '../config';

export const CurrentWorkspaceId = atom<string>({
  key: 'workspace',
  default: "",
  effects: [
    syncEffect({
        storeKey: 'filter',  
        refine: string(),
        read: ({read}) => {
            return read('workspace');
        },
        write: ({write, reset}, newValue) => {
            if (newValue instanceof DefaultValue) {
              reset('workspace');
            } else {
              write('workspace', newValue);
            }
          },
    })]
});

export interface PostmanCollectionItem {
  id: string;
  name: string;
  description: string;
}

export interface PostmanVariableItem {
  key: string;
  value: string;
  type: string;
}

export interface PostmanItem {
  id: string;
  type: string;
  name: string;
  description: string;
  variables: PostmanVariableItem[];
  collections: PostmanCollectionItem[];
}

export interface IWorkspaceItem {
  id: string;
  name: string;
  description: string;
  items: (PostmanItem)[]
}


export const WorkspacesQuery = selector<IWorkspaceItem[] | undefined>({
  key: 'WorkspacesQuery',
  get: async () => {
    let response = await RequestsBase.get<ApiResponse<IWorkspaceItem[]>>(`${config.apiUri}/api/v1/workspaces`);

    if(response.status !== 200) {
      throw new Error(response.statusText);
    }

    if(response.data.status !== 200) {
      throw new Error(response.data.message);
    }

    return response.data.data;
  },
});

export const CurrentWorkspaceQuery = selector<IWorkspaceItem>({
  key: 'CurrentWorkspaceQuery',
  get: ({get}) => {
    const workspaces = get(WorkspacesQuery);
    const result = workspaces?.find(w => w.id === get(CurrentWorkspaceId));
    if(!result) {
      throw new Error('workspace not found');
    }

    return result;
  }
});
