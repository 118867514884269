import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { RecoilURLSyncJSON } from 'recoil-sync';
import Home from './pages/home';
import Landing from './pages/landing';
import Settings from './pages/settings';
import WorkspaceSelector from './pages/workspace-selector';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <RecoilRoot>
        <RecoilURLSyncJSON
          storeKey="filter"
          location={{part: 'queryParams'}} >
            <Routes>
              <Route path="/" Component={Landing} />
              <Route path="/tool/workspace" Component={WorkspaceSelector}  />
              <Route path="/tool/settings" Component={Settings} />
              <Route path="/tool/home*" Component={Home} />
            </Routes>
          </RecoilURLSyncJSON>
      </RecoilRoot>
    </BrowserRouter >
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
