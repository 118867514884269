import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ReactMarkdown from 'react-markdown';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { CurrentPostmanCollectionFolderId, CurrentTestCollectionQuery, TestOutputState, TestVariablesState } from '../../../../recoil/test';
import { CurrentWorkspaceId } from '../../../../recoil/workspace';
import { RequestsBase } from '../../../../api/request';
import { ApiResponse } from '../../../../api/api-response.model';
import { config } from '../../../../config';
import { CurrentHomePageId } from '../../../../recoil/home';

const TestView = () => {
  const currentTestCollection = useRecoilValueLoadable(CurrentTestCollectionQuery);
  const [currentHomePageId] = useRecoilState(CurrentHomePageId);
  const [currentPostmanCollectionFolderId] = useRecoilState(CurrentPostmanCollectionFolderId);
  const [currentWorkspaceId] = useRecoilState(CurrentWorkspaceId);
  const [testOutputState, setTestOutputState] = useRecoilState(TestOutputState);
  const [testVariablesState] = useRecoilState(TestVariablesState);
  
  const outputKey = `${currentWorkspaceId}_${currentHomePageId}_${currentPostmanCollectionFolderId}`;
  const variableKey = `${currentWorkspaceId}_${currentHomePageId}`;

  const readEnvironment = () => {
    const currentEnvState = testVariablesState.get(variableKey);
    if(currentEnvState) {
      return Array.from(currentEnvState.keys()).map((key => {
        return ({
          key,
          value: currentEnvState.get(key)
        });
      }))
    }
  
    return [];
  }

  const runTest = async () => {
    let output = `<div class="test-info">INFO</div> Test started: ${new Date().toLocaleString()}</br>`;
    testOutputState.set(outputKey, output);
    setTestOutputState(new Map(testOutputState));

    try {
      const response = await RequestsBase.post<ApiResponse<any>>(`${config.apiUri}/api/v1/test`, {
        workspaceId: currentWorkspaceId,
        collectionId: currentHomePageId,
        folderId: currentPostmanCollectionFolderId,
        environment: readEnvironment()
      });

      output += response?.data.data.map((d: any) => {
        if(d.critical === true) {
          return `<div class="test-err">ERR</div> ${d.message}`
        }
        if(d.passed) {
          return `<div class="test-pass">PASS</div> ${d.message}`
        } else {
          return `<div class="test-fail">FAIL</div> ${d.message}`
        }
      }).join('</br>');
    } catch(e: any) {
      output += `${e.message}</br>`
    } finally {
      output += `</br><div class="test-info">INFO</div> Test completed: ${new Date().toLocaleString()}`;
    }

    testOutputState.set(outputKey, output);
    setTestOutputState(new Map(testOutputState));
  }

  if(currentTestCollection.state !== 'hasValue' || !currentTestCollection.contents) {
    return(<Box/>);
  }

  return(
    <Box>
      <ReactMarkdown>
        { currentTestCollection.contents.description }
      </ReactMarkdown>
      <Button variant="contained" onClick={ runTest } >Start Test</Button>
      <Box id="api-output" sx={{ marginTop: "24px" }} >
        <div className="content" dangerouslySetInnerHTML={{__html: testOutputState.has(outputKey) ? testOutputState.get(outputKey)! : ""}}></div>
      </Box>
    </Box>
  )
}

export default TestView;