import { Button, ButtonGroup, Box } from '@mui/material';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { useEffect } from 'react';
import { PostmanCollectionItem } from '../../../../recoil/workspace';
import { CurrentPostmanCollectionFolderId } from '../../../../recoil/test';
import { CurrentHomePageQuery } from '../../../../recoil/home';

const TestMenu = () => {
  const currentPage = useRecoilValueLoadable(CurrentHomePageQuery);
  const [currentPostmanCollectionFolderId, setCurrentPostmanCollectionFolderId] = useRecoilState(CurrentPostmanCollectionFolderId);
  
  useEffect(() => {
    if(currentPage.state === 'hasValue' && currentPage.contents) {
      const { collections } = currentPage.contents;
      if(collections && collections.length > 0) {
        if(!currentPostmanCollectionFolderId || !collections.find(m => m.id === currentPostmanCollectionFolderId)) {
          setCurrentPostmanCollectionFolderId(collections[0].id);
        }
      }
    }
  }, [currentPage]);

  const onMenuItemClick = (menuItemId: string) => {
    setCurrentPostmanCollectionFolderId(menuItemId);
  }

  return(
    <Box sx={{ marginTop: "1em" }} >
      <ButtonGroup
        sx={{ width: "100%" }}
        orientation="vertical"
        aria-label="vertical contained button group"
        
      >
        {currentPage.contents.collections.map((menuItem: PostmanCollectionItem) => {
          return(
            <Button 
              key={ menuItem.id } 
              onClick={ () => onMenuItemClick(menuItem.id) }
              variant={ menuItem.id === currentPostmanCollectionFolderId ? "contained" : "outlined" }
              disableElevation size='large'> 
                { menuItem.name } 
            </Button>
          )
        })}
      </ButtonGroup>
    </Box>
  )
}

export default TestMenu;