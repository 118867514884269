import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { CurrentWorkspaceId } from '../../../../recoil/workspace';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { CurrentPostmanCollectionFolderId, TestVariablesState } from '../../../../recoil/test';
import { CurrentHomePageId, CurrentHomePageQuery } from '../../../../recoil/home';

const TestVariables = () => {
  const currentPage = useRecoilValueLoadable(CurrentHomePageQuery);
  const [currentHomePageId] = useRecoilState(CurrentHomePageId);
  const [currentWorkspaceId] = useRecoilState(CurrentWorkspaceId);
  const stateKey = `${currentWorkspaceId}_${currentHomePageId}`;
  const [testVariablesState, setTestVariablesState] = useRecoilState(TestVariablesState);

  if(currentPage.state !== 'hasValue' || !currentPage.contents) {
    return(<Box/>);
  }

  if(currentWorkspaceId && currentHomePageId && !testVariablesState.has(stateKey)) {
    const variablesMap = new Map<string, string>()
    currentPage.contents.variables.forEach((v) => variablesMap.set(v.key, v.value));
    testVariablesState.set(stateKey, variablesMap);
    setTestVariablesState(new Map(testVariablesState));
  }

  const onHostChange = (e: any) => { 
    const variablesMap = testVariablesState.get(stateKey);
    if(variablesMap) {
      variablesMap?.set(e.target.id, e.target.value);
      testVariablesState.set(stateKey, variablesMap);
      setTestVariablesState(new Map(testVariablesState));
    }
  } 

  return(
    <Box>
        {currentPage.contents.variables.map((v) => {
          return(
            <Box key={v.key} sx={{ display: "flex", flexDirection: "row", paddingTop: "24px", maxWidth: "60%" }} >
              <TextField
                fullWidth={ true }
                id={ v.key }
                label={ v.key }
                defaultValue={ testVariablesState.get(stateKey)?.get(v.key) || v.value }
                size="small"
                onChange={ onHostChange }
              />
            </Box>
          )
        })}
    </Box>
  );
}

export default TestVariables;