import Box from '@mui/material/Box';
import { WorkspacesQuery, CurrentWorkspaceId } from '../../recoil/workspace';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import Header from '../header';
import { Card, Typography } from '@mui/material';
import { CurrentHomePageId } from '../../recoil/home';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: name[0].toUpperCase(),
  };
}

const WorkspaceSelector = () => {
  const workspacesQuery = useRecoilValueLoadable(WorkspacesQuery);
  const setCurrentWorkspaceId = useSetRecoilState(CurrentWorkspaceId);
  const setCurrentHomePageId = useSetRecoilState(CurrentHomePageId);
  const navigate = useNavigate();

  const handleListItemClick = (value: string) => {
    if(value) {
      setCurrentWorkspaceId(value);
      setCurrentHomePageId("");
      navigate(`/tool/home/postman?workspace="${value}"`);
    }
  };

  if(!workspacesQuery) {
    return <Box>Initializing...</Box>;
  }

  switch (workspacesQuery.state) {
    case 'loading':
      return <Box>Loading...</Box>;
    case 'hasError':
      return <Box>Error...</Box>;
    case 'hasValue':
      return(
        <Box>
          <Header title="Use Case Selection" />

          <Box sx={{ paddingTop: "200px" }}>
            <Card sx={{ maxWidth: "510px", marginLeft: "auto", marginRight: "auto" }}>
              <Typography variant="h6" component="div" sx={{ paddingTop: "24px", textAlign: "center" }} >
                Select a target app profile for use case payload
              </Typography>

              <List sx={{ pt: 0, margin: "24px auto 0" }}>
                {workspacesQuery.contents?.map((workspace: any) => (
                  <ListItem disableGutters>
                    <ListItemButton onClick={() => handleListItemClick(workspace.id)} key={workspace.id}>
                      <ListItemAvatar>
                        <Avatar {...stringAvatar(workspace.name)} />
                      </ListItemAvatar>
                      <ListItemText primary={workspace.name} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Card>
          </Box>

        </Box>
      )
  }
}

export default WorkspaceSelector;