import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Header from '../header';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { Route, Routes, useNavigate, useLocation, redirect } from 'react-router-dom';
import { CurrentWorkspaceId, CurrentWorkspaceQuery } from '../../recoil/workspace';
import { CurrentHomePageId } from '../../recoil/home';
import Postman from './postman';
import LeftDrawer from './drawer'

const mdTheme = createTheme();

const Home = () => {
  const [currentWorkspaceId] = useRecoilState(CurrentWorkspaceId);
  const currentWorkspace = useRecoilValueLoadable(CurrentWorkspaceQuery);

  const [currentHomePageId, setCurrentHomePageId] = useRecoilState(CurrentHomePageId);
  const navigate = useNavigate();
  const location = useLocation();

  if(!currentWorkspaceId) {
    navigate(`/tool/workspace`);
  }

  if(!currentWorkspace) {
    return <Box>Initializing...</Box>;
  }

  if(!currentHomePageId) {
    if(currentWorkspace.state === "hasValue") {
      const page = currentWorkspace.contents.items.find(p => p.type === "postman");
      if(page) {
        setCurrentHomePageId(page.id);
        navigate(`/tool/home/postman?workspace="${currentWorkspaceId}"`);
      }
    }
  } else if(location.pathname != '/tool/home/postman') {
    redirect(`/tool/home/postman?workspace="${currentWorkspaceId}"`);
  }

  switch (currentWorkspace.state) {
    case 'loading':
      return <Box>Loading...</Box>;
    case 'hasError':
      navigate(`/tool/workspace`);
      return(<div/>);
    case 'hasValue':
      return(
        <ThemeProvider theme={mdTheme}>
          <Box>
            <Header title={ currentWorkspace.contents!.name } />

            <LeftDrawer />

            <Box sx={{ ml: "300px" }} >
              <Routes>
                <Route path="/postman" Component={Postman}  />
              </Routes>
            </Box>

            
          </Box>
        </ThemeProvider>
      )
  }
}

export default Home;