import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MuiAppBar from '@mui/material/AppBar';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Help from '@mui/icons-material/Help';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useRecoilValueLoadable } from 'recoil';
import { UserQuery } from '../../recoil/user';
import { ReactComponent as PayloadPalLogo } from '../../assets/logo.svg';


const Header: React.FC<{title: string }> = ({ title }) => {
  const userQuery = useRecoilValueLoadable(UserQuery);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const openHelpPage = () => {
    window.open("https://f5-my.sharepoint.com/:w:/p/s_kuzmina/Eb93Wck9TG1On69kYoNHeN0BP5MoK4bFodRtnJyLmLE9uQ?e=6gYc7c", '_blank', 'noreferrer');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = () => {
    navigate(`/tool/workspace`);
  };

  const logout = () => {
    navigate("/oauth2/sign_out");
    navigate(0);
  }

  return (
    <Box>
      <MuiAppBar>
        <Toolbar
          sx={{
            pr: '24px',
          }}
        >
          <Stack direction="row" sx={{ flexGrow: 1 }}>
            <Box sx={{ marginRight: "24px" }}>
              <PayloadPalLogo height="32px" width="32px"  />
            </Box>
            <Typography variant="h6" component="div"  >
              { title }
            </Typography>
          </Stack>


          <div>
          <IconButton
              size="large"
              aria-label="help"
              aria-controls="menu-appbar"
              color="inherit"
              onClick={openHelpPage}
            >
              <Help />
            </IconButton>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={handleMenu}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={handleClose}
            >
              { userQuery.state === 'hasValue' 
                ?
                  <Stack sx={{ margin: "12px 16px" }} >
                    <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }} >
                      { userQuery.contents?.name }
                    </Typography>
                    <Typography variant="subtitle2" component="div" sx={{ flexGrow: 1 }} >
                      { userQuery.contents?.email }
                    </Typography>
                  </Stack> 
                : <br/>
              }
  
              <Divider />

              <MenuItem 
                onClick={handleDialogOpen} 
                disabled={ location.pathname === '/tool/workspace' } >
                  Switch Use Case
              </MenuItem>
              <MenuItem onClick={handleClose} >Settings</MenuItem>
              <Divider />
              <MenuItem onClick={logout} >Sign out</MenuItem>
            </Menu>
          </div>

        </Toolbar>
      </MuiAppBar>
    </Box>
  )
}

export default Header;