import Box from '@mui/material/Box';
import TestMenu from './components/test-menu';
import TestHeader from './components/test-header';
import TestView from './components/test-view';
import TestVariables from './components/test-variables';

const Postman = () => {
    return(
      <Box sx={{ width: "1024px", margin: "80px auto 0" }} >
        <TestHeader />
        <TestVariables />
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', marginTop: "24px" }} >
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: "0 35%", marginRight: "24px" }} >
            <TestMenu />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', flexBasis: '100%', flex: 1 }} >
            <TestView  />
          </Box>
        </Box>
      </Box>
    )
}

export default Postman;