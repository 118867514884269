import { atom, DefaultValue, selector, selectorFamily } from 'recoil';
import { syncEffect } from 'recoil-sync';
import { string } from '@recoiljs/refine';
import { RequestsBase } from '../api/request';
import { ApiResponse } from '../api/api-response.model';
import { config } from '../config';
import { CurrentWorkspaceQuery, PostmanCollectionItem, WorkspacesQuery } from './workspace';
import { CurrentHomePageQuery } from './home';

export const TestOutputState = atom<Map<string, string>>({
  key: 'TestOutputState',
  default: new Map<string, string>(),
});

export const TestVariablesState = atom<Map<string, Map<string,string>>>({
  key: 'TestVariablesState',
  default: new Map<string, Map<string,string>>(),
});

export const CurrentPostmanCollectionFolderId = atom<string>({
  key: 'CurrentPostmanCollectionFolderId',
  default: "",
});

export const CurrentTestCollectionQuery = selector<PostmanCollectionItem | undefined>({
  key: 'CurrentTestCollectionQuery',
  get: ({get}) => {
    const page = get(CurrentHomePageQuery);
    if(page && page.collections && page.collections.length > 0) {
      return page.collections.find(c => c.id === get(CurrentPostmanCollectionFolderId));
    }
  }
});
