import Box from '@mui/material/Box';
import ReactMarkdown from 'react-markdown';
import { useRecoilValueLoadable } from 'recoil';
import { CurrentWorkspaceQuery } from '../../../../recoil/workspace';
import { CurrentHomePageQuery } from '../../../../recoil/home';

const TestHeader = () => {
  const currentPage = useRecoilValueLoadable(CurrentHomePageQuery);

  return(
    <Box>
      <ReactMarkdown>
        { currentPage.contents.description }
      </ReactMarkdown>
    </Box>
  )
}

export default TestHeader;