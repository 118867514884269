import { atom, DefaultValue, selector, selectorFamily } from 'recoil';
import { syncEffect } from 'recoil-sync';
import { string } from '@recoiljs/refine';
import { RequestsBase } from '../api/request';
import { ApiResponse } from '../api/api-response.model';
import { config } from '../config';

export interface IUser { 
  name: string; 
  email: string; 
} 

export const UserQuery = selector<IUser | undefined>({ 
  key: 'UserQuery', 
  get: async ({ get }) => { 
    let response = await RequestsBase.get<ApiResponse<IUser>>(`${config.apiUri}/api/v1/user`);

    if(response.status !== 200) {
      throw new Error(response.statusText);
    }

    if(response.data.status !== 200) {
      throw new Error(response.data.message);
    }

    return response.data.data;
  }
});