import { atom, selector } from "recoil";
import { CurrentWorkspaceQuery, PostmanCollectionItem, PostmanItem, WorkspacesQuery } from "./workspace";

export const CurrentHomePageId = atom<string>({
  key: 'CurrentHomePageId',
  default: "",
});


export const CurrentHomePageQuery = selector<PostmanItem>({
  key: 'CurrentHomePageQuery',
  get: ({get}) => {
    const workspace = get(CurrentWorkspaceQuery);
    const currentHomePageId = get(CurrentHomePageId);
    const result = workspace?.items.find(w => w.id === currentHomePageId);
    if(!result) {
      throw new Error('page not found');
    }

    return result;
  }
});
