import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ReactComponent as PayloadPalLogo } from '../../assets/logo.svg';
import { useNavigate } from 'react-router-dom';

const Landing = () => {
  const navigate = useNavigate();

  const startClick = () => {
    navigate("/oauth2/start");
    navigate(0);
  }

  return(
    <Box sx={{ paddingTop: "200px", textAlign: "center", width: "100%" }} >
      <Box  sx={{ width: "510px", marginLeft: "auto", marginRight: "auto" }} >
        <Card sx={{ paddingTop: "65px", paddingBottom: "65px" }}>
          <Stack>
            <Box>
              <PayloadPalLogo height="48px" width="48px" />
            </Box>
            
            <h2>Payload Pal</h2>

            <Box>
              <Button variant="contained" sx={{ maxWidth: "400px" }} onClick={ startClick } >
                Sign in with Azure AD&nbsp;<OpenInNewIcon sx={{ height: "16px", width: "16px", marginBottom: "2px" }} />
              </Button>
            </Box>
            
          </Stack>

        </Card>
        <Box sx={{ marginTop: "8px", fontSize: "12px" }}>By proceeding with the sign-in, I acknowledge that I have read, understand, and agree to the terms of F5, Inc.&nbsp;
          <a style={{ textDecoration: "none" }} href="https://www.f5.com/pdf/customer-support/eusa.pdf" target="_blank" rel="noreferrer">End User Services Agreement <OpenInNewIcon sx={{ height: "14px", width: "14px", verticalAlign: "middle", marginBottom: "2px" }} /></a>
          &nbsp;and <a style={{ textDecoration: "none" }} href="https://www.f5.com/company/policies/privacy-notice" target="_blank" rel="noreferrer">F5 Privacy Policy <OpenInNewIcon sx={{ height: "14px", width: "14px", verticalAlign: "middle", marginBottom: "2px" }} /></a>
        </Box>
      </Box>

    </Box>
  );
}

export default Landing;