import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import YouTubeIcon from '@mui/icons-material/YouTube';
import GitHubIcon from '@mui/icons-material/GitHub';
import LanguageIcon from '@mui/icons-material/Language';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { CurrentWorkspaceQuery } from '../../../recoil/workspace';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { CurrentHomePageId } from '../../../recoil/home';

const LeftDrawer = () => {
  const currentWorkspace = useRecoilValueLoadable(CurrentWorkspaceQuery);
  const [currentHomepageId] = useRecoilState(CurrentHomePageId);
  const open = true;
  const drawerWidth = 300;

  if(!currentWorkspace) {
    return(<div/>);
  }

  const getIcon = (type: string) => {
    switch (type) {
      case "postman":
        return (<PlayArrowIcon />)
      case "youtube":
        return (<YouTubeIcon />);
      case "github":
        return (<GitHubIcon />);
      case "devcentral":
        return (<LanguageIcon />);
      default:
        return (<Box />)
    }
  } 

  const onItemClick = (item: any) => {
    switch(item.type) {
      case "youtube":
      case "github":
      case "devcentral":
        window.open(item.link, '_blank', 'noreferrer');
        break;
    }
  }

  switch (currentWorkspace.state) {
    case 'loading':
      return(<div/>);
    case 'hasError':
      return(<div/>);
    case 'hasValue':
    return(
      <Box  >
        <MuiDrawer variant="permanent"  sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { mt: "64px", width: drawerWidth, boxSizing: 'border-box' },
        }} >
          <Divider />
          <List>
            { currentWorkspace.contents.items.map((item) => (
              <ListItem key={item.id} disablePadding sx={{ display: 'block' }}>
                <ListItemButton onClick={ () => onItemClick(item) }
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5
                  }}
                  selected={ item.id === currentHomepageId }
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    { getIcon(item.type) }
                  </ListItemIcon>
                  <ListItemText 
                    primary={item.name} 
                    sx={{ 
                      opacity: open ? 1 : 0,
                    }} 
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </MuiDrawer>
      </Box>
    )
  }
}

export default LeftDrawer;